import { render, staticRenderFns } from "./PumzishaPlan.vue?vue&type=template&id=1f349480&scoped=true"
import script from "./PumzishaPlan.vue?vue&type=script&lang=js"
export * from "./PumzishaPlan.vue?vue&type=script&lang=js"
import style0 from "./PumzishaPlan.vue?vue&type=style&index=0&id=1f349480&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f349480",
  null
  
)

export default component.exports