<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip active-class="false" :to="{ name: 'Products' }">Products</v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'PumzishaPlan' }">{{ $options.name }}</v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card for Pumzisha Plan -->
    <v-card elevation="2"
      class="main-card tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-rounded-lg tw-shadow-lg tw-bg-white">
      <!-- Title Section with Icon -->
      <div class="tw-flex tw-items-center tw-mb-6">
        <img src="/images/icons/top-icon.png" alt="Pumzisha Icon" class="tw-w-10 tw-h-10 tw-mr-3" />
        <h2 class="tw-text-2xl tw-font-semibold c-blue-text">Pumzisha Plan</h2>
      </div>
      <!-- Form Container -->
      <div
        class="form-container tw-w-full tw-flex tw-flex-wrap tw-py-4 md:tw-py-6 tw-bg-gray-50 tw-rounded-md tw-shadow-inner lg:tw-shadow-lg">
        <keep-alive>
          <component :is="current" :productData="productData" :terms="terms" :quoteSummaryData="quoteSummaryData"
            @to-quote-summary="toQuoteSummary"></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/pumzisha/Quote.vue';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';
import BackButton from '../../components/BackButton';

export default {
  name: 'PumzishaPlan',
  metaInfo: {
    title: 'RFQ - Pumzisha Plan',
  },
  components: {
    QuoteSummary,
    BackButton,
    CardTitle,
    Default,
    ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2013105 },
      terms: [5, 10],
      ages: [
        { title: 'Age 23 - 29', value: '23-29' },
        { title: 'Age 30 - 34', value: '30-34' },
        { title: 'Age 35 - 39', value: '35-39' },
        { title: 'Age 40 - 44', value: '40-44' },
        { title: 'Age 45 - 49', value: '45-49' },
        { title: 'Age 50 - 54', value: '50-54' },
        { title: 'Age 55 - 59', value: '55-59' },
        { title: 'Age 60 - 64', value: '60-64' },
      ],
    };
  },
};
</script>

<style scoped>
.main-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.form-container {
  background-color: #f9fafb;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
}
</style>
